import React from "react";
import { useHistory } from "react-router-dom";
import PriceCard from "./PriceCard";
import URLS from "../../../store/constants/urls";
import useKeycloakService from "../../../repositories/accessManagement/Keycloak";
import i18next from "i18next";

const Pricing = (props) => {
    const { products, isLandingPage, selectedProduct, handleSelectChange } = props;
    const { register, authenticated } = useKeycloakService();
    let history = useHistory();

    const onChoosePlan = (item) => {
        if (isLandingPage) {
            if (!authenticated)
                register()
            else
                history.push(URLS.PROFILE_SUBSCRIPTION);
        }
        else handleSelectChange(item)
    }

    return <div className="pricing-section no-color text-center" id="pricing">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 ">
                    <div className="pricing-intro">
                        <h1 className="wow fadeInUp" data-wow-delay="0s">
                            {i18next.t("Pricing Table")}
                        </h1>
                        <p className="wow fadeInUp" data-wow-delay="0.2s">
                            Loream ipsum dummy text loream ipsum dummy text loream ipsum dummy text <br className="hidden-xs" /> loream ipsum
                            dummy text. Get the right plan that suits you.
                        </p>
                    </div>
                    <div className="row" style={{ background: "white" }}>
                        {products.map((item) => <PriceCard item={item} size={12 / products.length} selected={selectedProduct?.id === item.id} handleSelect={() => onChoosePlan(item)} isReadonly={false} />)}</div>
                </div>
            </div>
        </div>
    </div>
};

export default Pricing;
