import {
  FETCH_SUBPRODUCTS,
  FETCH_USER_SUBSCRIPTION,
  SET_SUBPRODUCTS,
  SET_USER_SUBSCRIPTION,
} from "../../constants/actions-types";

export const fetchSubProducts = (data) => ({
  type: FETCH_SUBPRODUCTS,
  payload: data,
});
export const setSubProducts = (data) => ({
  type: SET_SUBPRODUCTS,
  payload: data,
});
export const fetchUserSubscription = (data) => ({
  type: FETCH_USER_SUBSCRIPTION,
  payload: data,
});
export const setUserSubscription = (data) => ({
  type: SET_USER_SUBSCRIPTION,
  payload: data,
});