import i18next from "i18next";

export const months = () => {
  return {
    12: i18next.t("December"),
    11: i18next.t("November"),
    10: i18next.t("October"),
    9: i18next.t("September"),
    8: i18next.t("August"),
    7: i18next.t("July"),
    6: i18next.t("June"),
    5: i18next.t("May"),
    4: i18next.t("April"),
    3: i18next.t("March"),
    2: i18next.t("February"),
    1: i18next.t("January"),
  };
};

export const days = () => {
  return {
    0: i18next.t("Sun"),
    1: i18next.t("Mon"),
    2: i18next.t("Tue"),
    3: i18next.t("Wed"),
    4: i18next.t("Thu"),
    5: i18next.t("Fri"),
    6: i18next.t("Sat"),
  };
};

export const daysNumber = () => {
  return {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    "14": "14",
    "15": "15",
    "16": "16",
    "17": "17",
    "18": "18",
    "19": "19",
    "20": "20",
    "21": "21",
    "22": "22",
    "23": "23",
    "24": "24",
    "25": "25",
    "26": "26",
    "27": "27",
    "28": "28",
    "29": "29",
    "30": "30",
    "31": "31",
    lastDay: i18next.t("Last day")
  }
}

export const completeDays = () => {
  return {
    0: i18next.t("Sunday"),
    1: i18next.t("Monday"),
    2: i18next.t("Tuesday"),
    3: i18next.t("Wednesday"),
    4: i18next.t("Thusday"),
    5: i18next.t("Friday"),
    6: i18next.t("Saturday"),
  };
};

export const quarters = () => {
  return {
    1: i18next.t("1st Quarter"),
    2: i18next.t("2nd Quarter"),
    3: i18next.t("3rd Quarter"),
    4: i18next.t("4th Quarter"),
  };
};

export const getQuarter = (month) => {
  return Math.ceil(month / 3);
};

export const years = (startYear, maxFuture = 5) => {
  var maxYear = new Date().getFullYear() + maxFuture,
    years = {};
  startYear = startYear || 1970;
  while (startYear <= maxYear) {
    var year = startYear++;
    years[year] = year;
  }

  return years;
};

const formatDate = (date, withTime) => {
  let d = date ? new Date(date) : new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = d.getMinutes(),
    secondes = d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (secondes < 10) secondes = "0" + secondes;

  return withTime
    ? `${[day, month, year].join("/")} ${[hours, minutes, secondes].join(":")}`
    : [day, month, year].join("/");
};

export const getDateTime = (date) => {
  return formatDate(date, true);
};

export const getDate = (date) => {
  return formatDate(date, false);
};

export const getTimeOnly = (date) => {
  let d = date ? new Date(date) : new Date(),
    hours = d.getHours(),
    minutes = d.getMinutes();

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;

  return [hours, minutes].join(":");
};

export const getStartOfDay = (date) => {
  let d = date ? new Date(date) : new Date();

  return new Date(d.setHours(0, 0, 0, 0));
};

export const getTime = (date) => {
  var d = date ? new Date(date) : new Date();
  return d.getTime(); //millisecondes
};

export const dateToSecondes = (date) => {
  var d = date ? new Date(date) : new Date();
  return d.getTime() / 1000; //secondes
};

export const endOfYear = (date) => {
  let d = date ? new Date(date) : new Date(),
    year = d.getFullYear();
  return [12, 31, year].join("/") + " 23:59:59";
};

export const lastDayofMonth = (date) => {
  let d = date ? new Date(date) : new Date();
  return new Date(d.getFullYear(), d.getMonth() + 1, 0);
};

export const firstDayofMonth = (date) => {
  let d = date ? new Date(date) : new Date();
  return new Date(d.getFullYear(), d.getMonth(), 1);
};

export const GetAge = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const addDays = (date, days) => {
  if (!date || !days) return date;
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + days);

  return dateCopy;
};

export const getMonthDays = (month, year) => {
  let date = new Date(year, month, 0);
  const daysCount = date.getDate();
  date = new Date(year, month - 1, 1);
  const firstDay = date.getDay();

  let result = {};
  for (var i = 0; i < daysCount; i++) {
    result[i + 1] = days()[(firstDay + i) % 7];
  }
  return result;
};

export const getWorkingDays = (month, year, holidays) => {
  const monthDays = getMonthDays(month, year);
  const workingDays = [];
  Object.keys(monthDays).forEach((d) => {
    if (
      monthDays[d] !== days()[0] &&
      monthDays[d] !== days()[6] &&
      (!holidays || !holidays[month] || !holidays[month].includes(parseInt(d)))
    )
      workingDays.push(parseInt(d));
  });
  return workingDays;
};

export const dateToMinutes = (date, fromStart) => {
  var d = dateToSecondes(date, fromStart);
  return d / 60; //minutes
};

export const dateToHours = (date, fromStart) => {
  var d = dateToMinutes(date, fromStart);
  return d / 60; //hours
};

export const dateToDays = (date, fromStart) => {
  var d = dateToHours(date, fromStart);
  return d / 24; //hours
};

export const daysToDate = (date, fromStart) => {
  return getDateTime(date * 24 * 60 * 60 * 1000, fromStart);
};

export const hoursToDate = (date, fromStart) => {
  return getDateTime(date * 60 * 60 * 1000, fromStart);
};

export const minutesToDate = (date, fromStart) => {
  return getDateTime(date * 60 * 1000, fromStart);
};

export const minutesFromDate = (date, fromStart) => {
  return getDateTime(date * 60 * 1000, fromStart);
};

export const combineDateAndTime = (date, time) => {
  const timeString = new Date(time).getHours() + ":" + new Date(time).getMinutes() + ":00";

  var year = new Date(date).getFullYear();
  var month = new Date(date).getMonth() + 1; // Jan is 0, dec is 11
  var day = new Date(date).getDate();
  var dateString = "" + year + "-" + month + "-" + day;
  return new Date(dateString + " " + timeString);
};

export const getDateStringFormat = (dateTime, withTime) => {
  const hours = dateTime.getHours() < 10 ? "0" + dateTime.getHours() : dateTime.getHours(),
    minutes = dateTime.getMinutes() < 10 ? "0" + dateTime.getMinutes() : dateTime.getMinutes(),
    day = dateTime.getDay(),
    date = dateTime.getDate(),
    month = dateTime.getMonth() + 1,
    year = dateTime.getFullYear();

  return withTime ? `${completeDays()[day]} ${date} ${months()[month]} ${year} ${hours}:${minutes}`
    : `${completeDays()[day]} ${date} ${months()[month]} ${year}`
};

export const datesDifference = (date1, date2) => {
  const pastDate = new Date(date1);
  // Get the current time
  const futureDate = new Date(date2);
  // Calculate the difference in milliseconds
  const difference = futureDate - pastDate;

  // Convert the difference to seconds
  const secondes = Math.floor(difference / 1000);

  // Calculate the elapsed time in seconds, minutes, hours, and days
  const elapsedSeconds = secondes % 60;
  const elapsedMinutes = Math.floor(secondes / 60) % 60;
  const elapsedHours = Math.floor(secondes / (60 * 60)) % 24;
  const elapsedDays = Math.floor(secondes / (60 * 60 * 24));

  return {
    days: elapsedDays,
    hours: elapsedHours,
    minutes: elapsedMinutes,
    secondes: elapsedSeconds,
    millisecondes: difference
  };
}

export const cronTask = (secondes, func) => {
  const intervalId = setInterval(func, secondes * 1000);
  return () => {
    clearInterval(intervalId);
  };
}