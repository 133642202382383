import { FETCH_USER_SUBSCRIPTION } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { getUserSubscription } from "../../../repositories/subscription";
import { setUserSubscription } from "../../actions/subscription";
import { setNotification } from "../../actions/common";

const fetchUserSubscription = (action$) =>
  action$.pipe(
    ofType(FETCH_USER_SUBSCRIPTION),
    mergeMap(() =>
      merge(
        from(getUserSubscription()).pipe(
          flatMap((resp) => of(setUserSubscription(resp))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

export default fetchUserSubscription;
