import { Get, Post, tokenless } from "../../utils/base-api-request";
import { store } from "../../store/index";
import i18next from "i18next";

const getConfigUrl = process.env.REACT_APP_BASE_API + "api/subscription/config";
const getUserSubscriptionUrl = process.env.REACT_APP_BASE_API + "api/subscription";
const getActivesProductssUrl = process.env.REACT_APP_BASE_API + "api/subscription/products/actives";
const createCheckoutSessionUrl = process.env.REACT_APP_BASE_API + "api/subscription/create-checkout-session";
const getUserPortalLinkUrl = process.env.REACT_APP_BASE_API + "api/subscription/create-portal-link";

export const getConfig = async () => {
  const data = await Get(getConfigUrl);
  return data;
};

export const getUserSubscription = async () => {
  const data = await Get(getUserSubscriptionUrl + "/" + store.getState().authentication.user.id);
  return data;
};

export const getActivesProducts = async () => {
  const data = await Get(
    getActivesProductssUrl, { [tokenless]: true, "X-API-KEY": process.env.REACT_APP_API_KEY }
  );

  return data.map(product => subProductDtoToSubproductView(product));
};

export const createCheckoutSession = async (priceId) => {
  const data = await Post(createCheckoutSessionUrl, {
    userId: store.getState().authentication.user.id,
    email: store.getState().authentication.user.userName,
    priceId,
  });
  return data;
};

export const getUserPortalLink = async () => {
  const data = await Post(getUserPortalLinkUrl + "/" + store.getState().authentication.user.id);
  return data;
};

const subProductDtoToSubproductView = (product) => {
  const price = product.prices[0];
  return {
    id: product.id,
    image: product.image,
    title: product.name,
    description: product.description,
    priceId: price.id,
    amount: price.unitAmount,
    currency: price.currency === 'eur' ? "€" : price.currency,
    features: product.features,
    choosePlanText: i18next.t("Choose"),
    type: price.type,
    interval: price.interval,
    intervalCount: price.intervalCount,
    trialPeriodDays: price.trialPeriodDays
  }
};

// const subscriptionViewToSubscriptionDto = (item) => {
//   return item
// };
