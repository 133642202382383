import React from "react";
import URLS from "./store/constants/urls";

// const SignUp = React.lazy(() =>
//   import("./App/views/Authentication/SignUp/SignUp")
// );
// const SignIn = React.lazy(() =>
//   import("./App/views/Authentication/SignIn/SignIn")
// );
const Landing = React.lazy(() =>
  import("./App/layout/Landing")
);
const AdminLayout = React.lazy(() =>
  import("./App/layout/AdminLayout")
);
const route = [
  // { path: URLS.SIGNUP, exact: true, name: "Signup", component: SignUp },
  { path: URLS.LANDING, exact: true, name: "Source", component: Landing },
  { path: URLS.ADMIN, exact: true, name: "AdminLayout", component: AdminLayout },
];

export default route;
