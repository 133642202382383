import { store } from "../../store";
import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { getDateTime, getTime } from "../../utils/date";

const getAllUrl = process.env.REACT_APP_BASE_API + "api/Users";
const getUserRolesUrl =
  process.env.REACT_APP_BASE_API + "api/Users/userRole/{userId}";
const putUserRolesUrl = process.env.REACT_APP_BASE_API + "api/Users/userRole";
const putUserUrl = process.env.REACT_APP_BASE_API + "api/Users";
const putUserFreeAccessUrl = process.env.REACT_APP_BASE_API + "api/Users/freeAccess";

const getUserPermissionsUrl = process.env.REACT_APP_BASE_API + "api/Users/permissions/{userId}";

const putUserCredentialsUrl = process.env.REACT_APP_BASE_API + "api/Users/credentials";
const putUserProfilePictureUrl = process.env.REACT_APP_BASE_API + "api/Users/profilePicture";
const activateUserUrl = process.env.REACT_APP_BASE_API + "api/Users/{id}/{active}";
const getUserProfilePictureUrl = process.env.REACT_APP_BASE_API + "api/Users/profilePicture/{userId}";

const putParamsUrl = process.env.REACT_APP_BASE_API + "api/Users/parameters/{id}";

const registrationUrl = process.env.REACT_APP_BASE_API + "api/Users/register";
const authenticationUrl =
  process.env.REACT_APP_BASE_API + "api/Users/authenticate";
const removeUserUrl = process.env.REACT_APP_BASE_API + "api/Users/{userId}";

export const getAll = async () => {
  const users = await Get(getAllUrl);
  return users.map(user => userDTOToUserView(user));
};

export const getUserRoles = async (userId) => {
  return await Get(getUserRolesUrl.replace("{userId}", userId));
};

export const getUserPermissions = async () => {
  return await Get(getUserPermissionsUrl.replace("{userId}", store.getState().authentication.user.id));
};

export const updateUserRoles = async (userRoles) => {
  return await Put(putUserRolesUrl, userRoles);
};

export const update = async (user) => {
  const editedUser = await Put(putUserUrl, userViewToUserDTO(user));
  return userDTOToUserView(editedUser);
};

export const updateFreeAccess = async (user) => {
  const editedUser = await Put(putUserFreeAccessUrl, userViewToUserDTO(user));
  return userDTOToUserView(editedUser);
};

export const updateCredentials = async (id, userName, password) => {
  return await Put(putUserCredentialsUrl, { id, userName, password });
};

export const updateProfilePicture = async (id, name, content) => {
  return await Put(putUserProfilePictureUrl, { id, pictureName: name, pictureContent: content });
};

export const getProfilePicture = async () => {
  return await Get(getUserProfilePictureUrl.replace("{userId}", store.getState().authentication.user.id));
};

export const updateParams = async (params) => {
  return await Put(putParamsUrl.replace("{id}", store.getState().authentication.user.id), params);
};

export const activate = async (id, active) => {
  return await Put(activateUserUrl.replace("{id}", id).replace("{active}", active))
};

export const authentication = async () => {
  const user = await Post(authenticationUrl);
  return userDTOToUserView(user);
};

export const remove = async (id) => {
  return await Delete(removeUserUrl.replace("{userId}", id));
};


export const register = async (
  userName,
  firstName,
  lastName,
  mail,
  password
) => {
  return await Post(registrationUrl, { userName, firstName, lastName, mail, password });
};

const userDTOToUserView = (user) => {
  return {
    ...user,
    lastConnectionDate: getDateTime(user.lastConnectionDate)
  };
};

const userViewToUserDTO = (user) => {
  delete user.lastConnectionDate;
  return {
    ...user,
    birthDate: user.birthDate
      ? getTime(user.birthDate)
      : user.birthDate,
  };
};


