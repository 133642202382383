import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getTime } from "../../utils/date";

const getProfessionalInfosUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo/all";
const getProfessionalInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo";
const removeProfessionalInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo";
const addProfessionalInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo";
const updateProfessionalInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo";
const updateBankInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo/bankInfo";
const updateLogoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo/logo";
const updateSignatureUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo/signature";
const updateTvaInfoUrl =
  process.env.REACT_APP_BASE_API + "api/professionalInfo/tvaInfo";
const getInseeDataUrl =
  process.env.REACT_APP_BASE_API + "api/insee/find";
export const getAll = async () => {
  return await Get(
    getProfessionalInfosUrl + "/" + store.getState().authentication.user.id
  );
};

export const get = async (id) => {
  const profile = await Get(getProfessionalInfoUrl + "/" + id);
  return infoDtoToInfoView(profile);
};

export const add = async (professionalInfo) => {
  if (professionalInfo["userId"] === undefined)
    professionalInfo["userId"] = store.getState().authentication.user.id;

  const response = await Post(addProfessionalInfoUrl, infoViewToInfoDto(professionalInfo));
  return infoDtoToInfoView(response);
};

export const update = async (professionalInfo) => {
  if (professionalInfo["userId"] === undefined)
    professionalInfo["userId"] = store.getState().authentication.user.id;

  const response = await Put(updateProfessionalInfoUrl, infoViewToInfoDto(professionalInfo));
  return infoDtoToInfoView(response);
};

export const editLogo = async (id, content) => {
  const response = await Put(updateLogoUrl, { id, content });
  return infoDtoToInfoView(response);
};

export const editSignature = async (id, content) => {
  const response = await Put(updateSignatureUrl, { id, content });
  return infoDtoToInfoView(response);
};

export const updateBankInfo = async (id, bankName, bankNumber) => {
  const response = await Put(updateBankInfoUrl, { id, bankName, bankNumber });
  return infoDtoToInfoView(response);
};

export const updateTvaInfo = async (id, TvaNumber, tvaPeriodicity, tvaTaxeType) => {
  const response = await Put(updateTvaInfoUrl, { id, TvaNumber, tvaPeriodicity, tvaTaxeType });
  return infoDtoToInfoView(response);
};

export const remove = async (id) => {
  return await Delete(removeProfessionalInfoUrl + "/" + id);
};

export const findByParams = async (name, siren, siret) => {
  return await Get(getInseeDataUrl, undefined, { name, siren, siret });
};


const infoDtoToInfoView = (item) => {
  return {
    ...item,
    // startDate: formatDate(item.startDate),
  };
};

const infoViewToInfoDto = (item) => {
  return {
    ...item,
    startDate: item.startDate ? getTime(item.startDate) : 0,
  };
};
