import i18next from "i18next";

export const fr = "fr";
export const en = "en";
export const english = "English";
export const français = "Français";
export const paid = "paid";
export const unpaid = "unpaid";

export const bilingual = 2;
export const native = 3;

export const firstContact = "First Contact";
export const positionned = "Positionned";
export const onWaiting = "On Waiting";
export const aborted = "Aborted";
export const accepted = "Accepted";

export const basic = 1;
export const familiar = 2;
export const senior = 3;
export const expert = 4;

export const functionnal = "functionnal";
export const technical = "technical";
export const tool = "tool";
export const other = "other";
export const scheduled = "scheduled";

export const male = "male";
export const female = "female";

export const bill = "bill";
export const urssaf = "urssaf";
export const insurance = "insurance";
export const cpf = "cpf";
export const bankCharges = "bankCharges";
export const In = "in";
export const out = "out";
export const vat = "vat";
export const taxes = "taxes";

export const caHt = "caHt";
export const caTtc = "caTtc";

export const reminder = "reminder";
export const autoInput = "autoInput";

export const delta = "delta";
export const specificInterval = "specificInterval";

export const fixed = "fixed";
export const calculated = "calculated";

export const preferredLanguagesLookup = { [fr]: français, [en]: english };
export const billStateLookup = () => ({
  [paid]: i18next.t("Paid"),
  [unpaid]: i18next.t("Unpaid"),
});
export const cvSkillsTypeLookup = () => ({
  [functionnal]: i18next.t("Functionnal"),
  [technical]: i18next.t("Technical"),
  [tool]: i18next.t("Tool"),
  [other]: i18next.t("Other"),
});
export const cvLanguageLevelLookup = () => ({
  [bilingual]: i18next.t("Bilingual"),
  [native]: i18next.t("Native"),
});
export const offerStatusLookup = () => ({
  [firstContact]: i18next.t("First Contact"),
  [positionned]: i18next.t("Positionned"),
  [onWaiting]: i18next.t("On Waiting"),
  [aborted]: i18next.t("Aborted"),
  [accepted]: i18next.t("Accepted"),
});
export const cvSkillsLevelLookup = () => ({
  [basic]: i18next.t("Basic"),
  [familiar]: i18next.t("Familiar"),
  [senior]: i18next.t("Senior"),
  [expert]: i18next.t("Expert"),
});
export const profileGenderLookup = () => ({
  [male]: i18next.t("Male"),
  [female]: i18next.t("Female"),
});

export const transactionTypeLookup = () => ({
  [bill]: i18next.t("Bill"),
  [vat]: i18next.t("Vat"),
  [scheduled]: i18next.t("Scheduled"),
});

export const transactionOperationLookup = () => ({
  [In]: i18next.t("In"),
  [out]: i18next.t("Out"),
});

export const amountTypeLookup = () => ({
  [fixed]: i18next.t("Fixed amount"),
  [calculated]: i18next.t("Calculated amount"),
});

export const operationSourceLookup = () => ({
  [caTtc]: i18next.t("Turnover including tax"),
  [caHt]: i18next.t("Turnover excluding taxes"),
});

export const actionLookup = (operation) => ({
  [reminder]: i18next.t("Debit with delay"),
  [autoInput]: i18next.t("Automatically debit").replace("{operation}", operation === In ? i18next.t("credited") : i18next.t("debited")),
});

export const intervalLookup = () => ({
  [delta]: i18next.t("Since last successful execution"),
  [specificInterval]: i18next.t("Specific interval"),
});

export const subProductType_recurring = "recurring";
export const subProductType_oneTime = "one_time";

export const subProductInterval_day = "day";
export const subProductInterval_week = "week";
export const subProductInterval_month = "month";
export const subProductInterval_year = "year";

export const subProductType = () => ({
  [subProductType_oneTime]: i18next.t("One-off"),
  [subProductType_recurring]: i18next.t("Recurring"),
})

export const subProductInterval = () => ({
  [subProductInterval_day]: i18next.t("day"),
  [subProductInterval_week]: i18next.t("week"),
  [subProductInterval_month]: i18next.t("month"),
  [subProductInterval_year]: i18next.t("year"),
})

export const frenshImpots = (revenu) => {
  const bareme = {
    0: 10777,
    11: 27478,
    30: 78570,
    41: 168994,
    45: 168995,
  };
  let impot = 0;
  let previousLevel = 0;
  Object.keys(bareme).every((level) => {
    impot +=
      (level *
        ((revenu > bareme[level] ? bareme[level] : revenu) -
          bareme[previousLevel])) /
      100;
    previousLevel = level;
    if (revenu < bareme[level]) return false;
    return true;
  });

  return impot;
};

export const allColors = [
  "#1de9b6",
  '#008B8B',
  "#007FFF",
  '#9966CC',
  '#FF91AF',
  '#dc3545',
  '#FF0800',
  '#FF9966',
  '#DDD06A',
  '#993300',
  '#FF66CC',
  '#FDBCB4',
  '#536878',
  '#B2BEB5',
  '#C1F85C',
  '#AA98A9',
  '#36454F',
]

export const severityColor = {
  "warning": "#ffc107",
  "error": "#dc3545"
}