import React from "react";
import $ from "jquery";
import URLS from "./store/constants/urls";
import * as subjects from "./constants/subjects";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const DashboardDefault = React.lazy(() => import("./App/views/Dashboard"));
const BillsStats = React.lazy(() => import("./App/views/Charts/Bills"));

const BootstrapTable = React.lazy(() =>
  import("./App/components/Tables/BootstrapTable")
);
const OptionSet = React.lazy(() => import("./App/views/OptionSet/OptionSet"));
const PricePlan = React.lazy(() => import("./App/views/AccessManagement/PricePlan"));
const Roles = React.lazy(() =>
  import("./App/views/AccessManagement/Roles/Roles")
);
const Users = React.lazy(() =>
  import("./App/views/AccessManagement/Users/Users")
);
const Contact = React.lazy(() =>
  import("./App/views/Prospecting/Contact/Contact")
);
const Offer = React.lazy(() => import("./App/views/Prospecting/Offer/Main"));
const PersonalInfo = React.lazy(() => import("./App/views/Profile/Profile"));
const Timesheet = React.lazy(() => import("./App/views/Reporting/Cra/Cra"));
const Contract = React.lazy(() =>
  import("./App/views/Reporting/Project/Main")
);
const Billing = React.lazy(() => import("./App/views/Billing/Main"));
const Company = React.lazy(() =>
  import("./App/views/Prospecting/Company/Company")
);
const Transaction = React.lazy(() =>
  import("./App/views/Portfolio/Transaction/Transaction")
);
const Charge = React.lazy(() => import("./App/views/Portfolio/Charges"));
const Cv = React.lazy(() => import("./App/views/Cv/Cv"));
const Calendar = React.lazy(() => import("./App/views/Planning/Calendar"));
const Product = React.lazy(() => import("./App/views/Ecommerce/Product/Main"));

const Landing = React.lazy(() => import("./App/layout/Landing"));
const TaskBoard = React.lazy(() => import("./App/views/TaskBoard"));
const Subscription = React.lazy(() => import("./App/views/Subscription"));

const routes = [
  {
    id: subjects.DASHBOARD,
    path: URLS.DASHBOARD_DEFAULT,
    exact: true,
    name: "Default",
    component: DashboardDefault,
  },
  {
    id: subjects.STATS_BILLS,
    path: URLS.DASHBOARD_STATS_BILLS,
    exact: true,
    name: "Stats",
    component: BillsStats,
  },
  {
    path: "/tables/bootstrap",
    exact: true,
    name: "Bootstrap Table",
    component: BootstrapTable,
  },
  {
    id: subjects.OPTIONSET,
    path: URLS.SETTINGS_OPTIONSET,
    exact: true,
    name: "Option Set",
    component: OptionSet,
  },
  {
    id: subjects.PRICEPLAN_ROLES,
    path: URLS.SETTINGS_PRICEPLAN_ROLES,
    exact: true,
    name: "Price Plan",
    component: PricePlan,
  },
  {
    id: subjects.COMPANIES,
    path: URLS.PROSPECTING_COMPANY,
    exact: true,
    name: "Company",
    component: Company,
  },
  {
    id: subjects.CONTACTS,
    path: URLS.PROSPECTING_CONTACT,
    exact: true,
    name: "Contact",
    component: Contact,
  },
  {
    id: subjects.OFFERS,
    path: URLS.PROSPECTING_OFFER,
    exact: true,
    name: "Offer",
    component: Offer,
  },
  {
    id: subjects.PERSONALINFO,
    path: URLS.PROFILE_PERSONAL,
    exact: true,
    name: "PersonalInfo",
    component: PersonalInfo,
  },
  {
    id: subjects.SUBSCRIPTION,
    path: URLS.PROFILE_SUBSCRIPTION,
    exact: true,
    name: "Subscription",
    component: Subscription,
  },
  {
    id: subjects.TIMESHEETS,
    path: URLS.MANAGEMENT_TIMESHEET,
    exact: true,
    name: "Timesheet",
    component: Timesheet,
  },
  {
    id: subjects.CONTRACTS,
    path: URLS.MANAGEMENT_CONTRACT,
    exact: true,
    name: "contract",
    component: Contract,
  },
  {
    id: subjects.BILLING,
    path: URLS.BILLING,
    exact: true,
    name: "Billing",
    component: Billing,
  },
  {
    id: subjects.CV,
    path: URLS.CV,
    exact: true,
    name: "CV",
    component: Cv,
  },
  {
    id: subjects.ROLES,
    path: URLS.SETTINGS_ROLE,
    exact: true,
    name: "Role",
    component: Roles,
  },
  {
    id: subjects.USERS,
    path: URLS.SETTINGS_USER,
    exact: true,
    name: "User",
    component: Users,
  },
  {
    id: subjects.CHARGES,
    path: URLS.PORTFOLIO_CHARGES,
    exact: true,
    name: "Charge",
    component: Charge,
  },
  {
    id: subjects.TRANSACTIONS,
    path: URLS.PORTFOLIO_TRANSACTIONS,
    exact: true,
    name: "Transaction",
    component: Transaction,
  },
  {
    id: subjects.EVENTS,
    path: URLS.PLANNING_CALENDAR,
    exact: true,
    name: "Calendar",
    component: Calendar,
  },
  {
    id: subjects.PRODUCTS,
    path: URLS.ECOMMERCE_PRODUCTS,
    exact: true,
    name: "Product",
    component: Product,
  },
  {
    id: subjects.CAMPAIGNS,
    path: URLS.ECOMMERCE_CAMPAIGNS,
    exact: true,
    name: "Calendar",
    component: Calendar,
  },
  {
    id: subjects.LANDING,
    path: URLS.LANDING,
    exact: true,
    name: "Source",
    component: Landing
  },
  {
    id: subjects.TASK_BOARD,
    path: URLS.MANAGEMENT_TASK_BOARD,
    exact: true,
    name: "Board",
    component: TaskBoard
  }
];

export default routes;
