
import { useKeycloak } from "@react-keycloak/web";

function useKeycloakService() {
    const { keycloak } = useKeycloak();

    const logout = () => {
        keycloak.logout({ redirectUri: window.location.origin })
    }

    return {
        authenticated: keycloak.authenticated
        , login: () => keycloak.login()
        , register: () => keycloak.register()
        , token: keycloak.token
        , tokenParsed: keycloak.tokenParsed
        , logout: () => logout()
        , updateToken: (successCallback) => keycloak.updateToken(5).then(successCallback).catch(keycloak.login)
    };
}

export default useKeycloakService;

