import i18next from "i18next";
import React from "react";
import { Button } from "react-bootstrap";
import { subProductInterval } from "../../../utils/constants";

const PriceCard = (props) => {
    const { item, selected, handleSelect, isReadonly, size } = props;

    return (
        <div className={"col-sm-" + size}>
            <div className="table-right wow fadeInUp" style={{ boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)" }} data-wow-delay="0.6s">
                {item.image && <div className="icon">
                    <img src={item.image} alt="Icon" />
                </div>}
                <div className="pricing-details">
                    <h2>{item.title}</h2>
                    <span style={{ color: "black" }}>{`${item.amount}${item.currency} /${item.intervalCount > 1 ? item.intervalCount + " " : ""}${subProductInterval()[item.interval]}`}</span>
                    <div style={{ height: "50px" }}>
                        <p>
                            {item.description}
                        </p>
                    </div>
                    <div style={{ minHeight: '370px' }}>
                        <ul style={{ textAlign: "left", listStyle: "outside" }}>
                            {item.features.map(feature => <li> {feature}</li>)}
                        </ul>
                    </div>
                    {!selected && !isReadonly && <Button variant="primary" onClick={handleSelect} block>{item.choosePlanText}</Button>}
                    {selected && !isReadonly && <Button variant="outline-secondary" block disabled>
                        {i18next.t("Selected plan")}
                    </Button>}
                </div>
            </div>
        </div>
    );
};

export default PriceCard;
