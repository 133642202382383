import { GET_USER_PICTURE } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setUserPicture } from "../../actions/authentication";
import { getProfilePicture } from "../../../repositories/accessManagement/user";
import { setNotification } from "../../actions/common";

const getUserPicture = (action$) =>
  action$.pipe(
    ofType(GET_USER_PICTURE),
    switchMap(() =>
      from(getProfilePicture()).pipe(
        flatMap((data) =>
          of(
            setUserPicture({ name: "file.name", content: data })
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default getUserPicture;
