import {
  SET_PROFILE,
  SET_CONTACT,
  SET_OFFER,
  SET_COMPANY,
  SET_CERTIFICATION,
  SET_MISSION,
  SET_INTEREST,
  SET_LANGUAGE,
  SET_SKILL,
  SET_STUDY,
  SET_TRAINING,
  SET_PROFESSIONALINFO,
  SET_PROFESSIONALINFO_LOGO,
  SET_PROJECT,
  SET_COMPANY_SUGGESTIONS,
  SET_BILL,
  SIGNOUT,
  SET_TRANSACTION,
  SET_STATISTICS,
  SET_CHARGE,
  SET_EVENT,
  SET_INCOMES,
  SET_OUTCOMES,
  SET_PROFESSIONALINFO_SIGNATURE
} from "../../constants/actions-types";

const initialState = {
  profile: {},
  contacts: [],
  offers: [],
  companies: [],
  cv: {
    profile: {},
    certifications: [],
    missions: [],
    interests: [],
    languages: [],
    skills: [],
    studies: [],
    trainings: [],
  },
  reporting: {
    projects: [],
  },
  // bills: [],
  charges: [],
  statistics: {},
  incomes: [],
  outcomes: [],
  // transactions: [],
  events: { start: 0, end: 0, data: [] },
  companySuggestions: [],
};

const handlers = {
  [SIGNOUT]() {
    return initialState;
  },
  [SET_CONTACT](state, payload) {
    return {
      ...state,
      contacts: payload,
    };
  },
  [SET_OFFER](state, payload) {
    return {
      ...state,
      offers: payload,
    };
  },
  [SET_COMPANY](state, payload) {
    return {
      ...state,
      companies: payload,
    };
  },
  [SET_COMPANY_SUGGESTIONS](state, payload) {
    return {
      ...state,
      companySuggestions: payload,
    };
  },
  [SET_PROFILE](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        profile: payload,
      },
    };
  },
  [SET_CERTIFICATION](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        certifications: payload,
      },
    };
  },
  [SET_MISSION](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        missions: payload,
      },
    };
  },
  [SET_INTEREST](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        interests: payload,
      },
    };
  },
  [SET_LANGUAGE](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        languages: payload,
      },
    };
  },
  [SET_SKILL](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        skills: payload,
      },
    };
  },
  [SET_STUDY](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        studies: payload,
      },
    };
  },
  [SET_TRAINING](state, payload) {
    return {
      ...state,
      cv: {
        ...state.cv,
        trainings: payload,
      },
    };
  },
  [SET_PROFESSIONALINFO](state, payload) {
    return {
      ...state,
      profile: {
        ...state.profile,
        professionalInfo: payload,
      },
    };
  },
  [SET_PROFESSIONALINFO_LOGO](state, payload) {
    return {
      ...state,
      profile: {
        ...state.profile,
        professionalInfo: { ...state.profile.professionalInfo, ...{ logo: payload } },
      },
    };
  },
  [SET_PROFESSIONALINFO_SIGNATURE](state, payload) {
    return {
      ...state,
      profile: {
        ...state.profile,
        professionalInfo: { ...state.profile.professionalInfo, ...{ signature: payload } },
      },
    };
  },
  [SET_PROJECT](state, payload) {
    return {
      ...state,
      reporting: {
        ...state.reporting,
        projects: payload,
      },
    };
  },
  [SET_BILL](state, payload) {
    return {
      ...state,
      bills: payload,
    };
  },
  [SET_TRANSACTION](state, payload) {
    return {
      ...state,
      transactions: payload,
    };
  },
  [SET_STATISTICS](state, payload) {
    return {
      ...state,
      statistics: payload,
    };
  },
  [SET_CHARGE](state, payload) {
    return {
      ...state,
      charges: payload,
    };
  },
  [SET_INCOMES](state, payload) {
    return {
      ...state,
      incomes: payload,
    };
  },
  [SET_OUTCOMES](state, payload) {
    return {
      ...state,
      outcomes: payload,
    };
  },
  [SET_EVENT](state, payload) {
    return {
      ...state,
      events: {
        ...payload, ...{
          lastUpdateDate: new Date()
        }
      },
    };
  },
};

const DataSetReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default DataSetReducer;
