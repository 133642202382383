import { FETCH_USER } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { fetchData } from "../../actions/common";
import { setUserData } from "../../actions/authentication";
import { authentication } from "../../../repositories/accessManagement/user";
import { setNotification } from "../../actions/common";
import { defineRulesFor } from "../../../repositories/authorization";
import keycloak from '../../../Keycloak.js';

const fetchUser = (action$) =>
  action$.pipe(
    ofType(FETCH_USER),
    switchMap(() =>
      from(authentication()).pipe(
        flatMap((data) =>
          of(
            setUserData({
              ...data,
              ...{
                permissions: data.permissions,
                ability: defineRulesFor(data.permissions),
              },
            },
              true),
            fetchData()
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: error,
            }),
            keycloak.logout()
          )
        )
      )
    )
  );

export default fetchUser;
