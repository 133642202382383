import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import i18next from "i18next";

const getSettingsByTypeUrl = process.env.REACT_APP_BASE_API + "api/Settings";
const getSettingsUrl = process.env.REACT_APP_BASE_API + "api/Settings/all";
const getSettingUrl = process.env.REACT_APP_BASE_API + "api/Settings";
const removeSettingUrl = process.env.REACT_APP_BASE_API + "api/Settings";
const addSettingUrl = process.env.REACT_APP_BASE_API + "api/Settings";
const updateSettingUrl = process.env.REACT_APP_BASE_API + "api/Settings";

export const getAll = async () => {
  return await Get(getSettingsUrl);
};

export const getByType = async (type) => {
  return await Get(getSettingsByTypeUrl + "/" + type);
};

export const get = async (id) => {
  return await Get(getSettingUrl + "/" + id);
};

export const add = async (setting) => {
  return await Post(addSettingUrl, setting);
};

export const update = async (setting) => {
  return await Put(updateSettingUrl, setting);
};

export const remove = async (id) => {
  return await Delete(removeSettingUrl + "/" + id);
};

export const lookupData = (view, type) => {
  const state = store.getState();
  const optionSet = state.configuration.optionSet;

  let res = {};
  if (optionSet) {
    optionSet
      .filter((item) => item.component === view && item.lookup === type)
      .forEach((setting) => (res[setting.value] = i18next.t(setting.display)));
    return res;
  }
  return {};
};
