import { FETCH_SUBPRODUCTS } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { getActivesProducts } from "../../../repositories/subscription";
import { setSubProducts } from "../../actions/subscription";
import { setNotification } from "../../actions/common";

const fetchSubProducts = (action$) =>
  action$.pipe(
    ofType(FETCH_SUBPRODUCTS),
    mergeMap(({ payload }) =>
      merge(
        from(getActivesProducts(payload)).pipe(
          flatMap((resp) => of(setSubProducts(resp))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

export default fetchSubProducts;
