import chroma from "chroma-js";
import i18next from "i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";

export const getBreadCrumbFromUrl = (url) => {
  const splittedUrl = url.split("/");
  if (splittedUrl.length > 4) {
    splittedUrl.splice(0, 4);
    return splittedUrl.join(" > ");
  }
  return "";
};

export const getBreadCrumbFromArray = (array, prevText = "") => {
  if (prevText === "") return array.join(" > ");
  return prevText + " > " + array.join(" > ");
};

export const getBreadCrumbFromArrayWithActions = (array) => {
  let counter = array.length - 1;
  const res = array.map((item) => (
    <>
      <React.Fragment>{item}</React.Fragment>
      <React.Fragment>{counter-- > 0 && " > "}</React.Fragment>
    </>
  ));
  return res;
};

export const generateHeader = (title, action) => (
  <div
    className="d-inline"
    style={{
      cursor: "pointer",
      textDecoration: "underline",
    }}
    onClick={action}
  >
    {title}
  </div>
);

export const toReadOnly = (render) => {
  return Object.assign({ ...render }, { isReadOnly: true });
};

export const toFormType = (render, type) => {
  return Object.assign({ ...render }, { formType: type });
};

export const generateLabelWithStyle = (field, value, css, columnSizes) => {
  return (
    <Row>
      <Col sm={columnSizes && columnSizes.left ? columnSizes.left : "4"}>
        <span
          style={css && css.left ? {
            ...{
              fontSize: "1.1em",
              fontWeight: "bolder",
            }, ...css.left
          } : {
            fontSize: "1.1em",
            fontWeight: "bolder",
          }}
        >
          {field}
        </span>
      </Col>
      <Col sm={columnSizes && columnSizes.right ? columnSizes.right : "8"} >
        <span
          style={css && css.left ? {
            ...{
              fontSize: "1.1em",
            }, ...css.right
          }
            : {
              fontSize: "1.1em",
            }}
        >
          {value}
        </span>
      </Col>
    </Row>
  );
};

export const generateLabel = (field, value) => {
  return (
    <>
      <span
        style={{
          fontSize: "1.2em",
          fontWeight: "bolder",
          paddingRight: "9px",
        }}
      >
        {`${field}:`}
      </span>
      <span
        style={{
          fontSize: "1.1em",
        }}
      >
        {value}
      </span>
    </>
  );
};

export const copyrightText = () => `© ${new Date().getFullYear()}, Octolance, Inc. ${i18next.t("All Rights Reserved")}.`


export const adjustColor = (item) => {
  const color = chroma(item);
  return {
    backgroundColor: item,
    color: chroma.contrast(color, 'white') > 2
      ? 'white'
      : 'black',
    adjustedColor: color.alpha(0.9).css()
  };
}
