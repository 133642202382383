import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getDateTime, getTime } from "../../utils/date";

const getOffersUrl = process.env.REACT_APP_BASE_API + "api/offer/all";
const getOfferUrl = process.env.REACT_APP_BASE_API + "api/offer";
const removeOfferUrl = process.env.REACT_APP_BASE_API + "api/offer";
const addOfferUrl = process.env.REACT_APP_BASE_API + "api/offer";
const updateOfferUrl = process.env.REACT_APP_BASE_API + "api/offer";

const sortData = (array) => {
  return array.sort((a, b) => {
    const date1 = getTime(a.lastModificationDate);
    const date2 = getTime(b.lastModificationDate);
    if (date1 < date2) return 1;
    if (date1 > date2) return -1;

    return 0;
  });
};

export const getAll = async () => {
  const offers = await Get(
    getOffersUrl + "/" + store.getState().authentication.user.id
  );

  return sortData(offers).map(offer => offerDtoToOfferView(offer));
};

export const get = async (id) => {
  const offer = await Get(getOfferUrl + "/" + id);
  return offerDtoToOfferView(offer);
};

export const add = async (offer) => {
  if (offer["userId"] === undefined)
    offer["userId"] = store.getState().authentication.user.id;

  const editedOoffer = await Post(addOfferUrl, offerViewToOfferDto(offer));
  return offerDtoToOfferView(editedOoffer);
};

export const update = async (offer) => {
  if (offer["userId"] === undefined)
    offer["userId"] = store.getState().authentication.user.id;

  const editedOoffer = await Put(updateOfferUrl, offerViewToOfferDto(offer));
  return offerDtoToOfferView(editedOoffer);
};

export const remove = async (id) => {
  return await Delete(removeOfferUrl + "/" + id);
};

export const lookup = (offers) => {
  let lookup = {};
  offers.data.forEach((offer) => (lookup[offer.id] = offer.title));

  return lookup;
};

const offerViewToOfferDto = (item) => {
  delete item.lastModificationDate;
  return {
    ...item
  };
};

const offerDtoToOfferView = (item) => {
  return {
    ...item,
    lastModificationDate: getDateTime(item.lastModificationDate),
  };
};
