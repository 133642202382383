import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions.js";

export const defineRulesForSubscriptions = (can, roles) => {
    if ([permissions.ALL].some((role) => roles.includes(role))) {
        can(permissions.MANAGE, subjects.SUBSCRIPTION);
    } else {
        roles.forEach((perm) => {
            can(perm, subjects.SUBSCRIPTION);
        });
    }
};