import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForAccessRoles = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.ROLES);
    can(permissions.MANAGE, subjects.USERS);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.ROLES);
      can(perm, subjects.USERS);
    });
  }
};

export const canHandleRoles = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.ROLE_MANAGE, subjects.ROLES);

export const canHandleUsers = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.USER_MANAGE, subjects.USERS);

export const canHandlePricePlan = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.PRICEPLAN_ROLES_MANAGE, subjects.PRICEPLAN_ROLES);    
